var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vf-music"},[_c('div',{staticClass:"vf-music-table",style:(_vm.pager ? 'padding-bottom: 150px' : 'padding-bottom: 48px')},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOrder),expression:"showOrder"}],staticClass:"order_cont"},[_c('label',{attrs:{"for":""}},[_vm._v("排序：")]),_c('el-select',{attrs:{"placeholder":"请选择","popper-append-to-body":false},on:{"change":_vm.changeMusicOrder},model:{value:(_vm.orderNo),callback:function ($$v) {_vm.orderNo=$$v},expression:"orderNo"}},_vm._l((_vm.orderList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(_vm.header)?_c('div',{staticClass:"row header"},[_c('div',{staticClass:"column"},[_vm._v("歌曲信息")]),_c('div',{staticClass:"column"},[_vm._v("标签")]),_c('div',{staticClass:"column"},[_vm._v("时长")]),_c('div',{staticClass:"column"}),_c('div',{staticClass:"column"})]):_vm._e(),_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"row music-item",on:{"mouseover":function($event){return _vm.handleMouseover(index)},"mouseleave":function($event){return _vm.handleMouseleave()}}},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"before-box"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"cover",on:{"click":function($event){return _vm.handlePlayerMusic({ ...item, type: 2, labels: item.label })}}},[_c('el-image',{staticClass:"cover-main",attrs:{"fit":'cover',"src":item.logo}}),_c('img',{staticClass:"cover-fixed",attrs:{"src":require('@/assets/svg/record.svg')}})],1),_c('div',{staticClass:"detail"},[_c('p',{staticClass:"detail-name"},[_c('label',{on:{"click":function($event){return _vm.handlePlayerMusic({ ...item, type: 2, labels: item.label })}}},[_vm._v(_vm._s(item.name))]),(index < 5 && _vm.count == 1)?_c('img',{attrs:{"src":require(`@/assets/svg/${_vm.orderNo == 2 ? 'hot-badge.svg' : 'new-icon.svg'
                  }`)}}):_vm._e()]),_c('p',{staticClass:"detail-info"},[_vm._v("BPM："+_vm._s(item.bpm))])])]),_c('div',{staticClass:"after-box"})]),_c('div',{staticClass:"column tag"},[(item.label && item.label.length > 0)?_c('div',_vm._l((item.label.length
            ? item.label.slice(0, 5)
            : []),function(tag,tagIndex){return _c('Tag',{key:tagIndex,attrs:{"mode":"text","plain":""},domProps:{"innerHTML":_vm._s(_vm.brightenKeyword(tag.name, _vm.keywordName))}},[_vm._v(_vm._s(tag.name)+" ")])}),1):_c('Tag',{attrs:{"mode":"text","plain":""}},[_vm._v("-")])],1),_c('div',{staticClass:"column duration"},[_vm._v(" "+_vm._s(_vm._f("formatTimeBySecond")(item.duration))+" ")]),_c('div',{staticClass:"column icon"},[_c('img',{attrs:{"src":require(`@/assets/svg/${item.is_collect === 0 ? 'collect' : 'collected'
            }.svg`)},on:{"click":function($event){return _vm.handleMusicCollect(item)}}}),_c('img',{attrs:{"src":require('@/assets/svg/share-gray.svg')},on:{"click":function($event){return _vm.handleShare('', item.object_id, 2, item.name)}}}),_c('img',{attrs:{"src":require('@/assets/svg/download-gray.svg')},on:{"click":function($event){return _vm.handleDownload(item, 'single')}}}),(!_vm.button)?_c('img',{attrs:{"src":require('@/assets/svg/share-gray.svg')}}):_vm._e()]),(_vm.button)?_c('div',{staticClass:"column btn"},[_c('div',{staticClass:"btn-content"},[_c('div',{staticClass:"btn-car",on:{"click":function($event){$event.stopPropagation();return _vm.handleAddGoodsToCart({
              object_id: item.object_id,
              type: 2,
              name: item.name
            })}}},[_vm._v(" 加入购物车 ")])])]):_vm._e()])}),(_vm.pager)?_c('div',{staticClass:"vf-music-pager"},[(_vm.total > 0)?_c('Pagination',{attrs:{"limit":_vm.pageSize,"page":_vm.currentPage,"total":_vm.total},on:{"update:limit":function($event){_vm.pageSize=$event},"update:page":function($event){_vm.currentPage=$event},"pagination":_vm.pagination}}):_vm._e()],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }