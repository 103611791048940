<template>
  <div class="vf-music">
    <div class="vf-music-table" :style="pager ? 'padding-bottom: 150px' : 'padding-bottom: 48px'">
      <div class="order_cont" v-show="showOrder">
        <label for="">排序：</label>
        <el-select v-model="orderNo" placeholder="请选择" :popper-append-to-body="false" @change="changeMusicOrder">
          <el-option v-for="item in orderList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!-- 表头 -->
      <div class="row header" v-if="header">
        <div class="column">歌曲信息</div>
        <div class="column">标签</div>
        <div class="column">时长</div>
        <div class="column"></div>
        <div class="column"></div>
      </div>
      <!-- 列表内容 -->
      <div v-for="(item, index) in data" :key="index" class="row music-item" @mouseover="handleMouseover(index)"
        @mouseleave="handleMouseleave()">
        <!-- 歌曲信息 -->
        <div class="column">
          <!-- 盒子 -->
          <div class="before-box"></div>
          <div class="content">
            <!-- 封面 -->
            <div class="cover" @click="
              handlePlayerMusic({ ...item, type: 2, labels: item.label })
            ">
              <el-image :fit="'cover'" class="cover-main" :src="item.logo" />
              <!-- <div class="mask_image" :class="{'active-mask': ind == index}">
                <img
                  :src="
                    require(`@/assets/svg/${
                      ind == index && playing ? 'pause' : 'play'
                    }-audio-icon.svg`)
                  "
                />
              </div> -->
              <img class="cover-fixed" :src="require('@/assets/svg/record.svg')" />
            </div>
            <div class="detail">
              <p class="detail-name">
                <label @click="
                  handlePlayerMusic({ ...item, type: 2, labels: item.label })
                ">{{ item.name }}</label>
                <!--                <span class="detail-hot" v-if="index < 5 && (count ==1)">最近更新</span>-->
                <img v-if="index < 5 && count == 1" :src="
                  require(`@/assets/svg/${orderNo == 2 ? 'hot-badge.svg' : 'new-icon.svg'
                    }`)
                " />
              </p>
              <p class="detail-info">BPM：{{ item.bpm }}</p>
            </div>
          </div>
          <!-- 盒子 -->
          <div class="after-box"></div>
        </div>
        <!-- 标签 -->
        <div class="column tag">
          <div v-if="item.label && item.label.length > 0">
            <Tag v-for="(tag, tagIndex) in item.label.length
              ? item.label.slice(0, 5)
              : []" :key="tagIndex" mode="text" plain v-html="brightenKeyword(tag.name, keywordName)">{{ tag.name }}
            </Tag>
          </div>
          <Tag v-else mode="text" plain>-</Tag>
        </div>
        <!-- 时长 -->
        <div class="column duration">
          {{ item.duration | formatTimeBySecond }}
        </div>
        <!-- icon -->
        <div class="column icon">
          <img :src="
            require(`@/assets/svg/${item.is_collect === 0 ? 'collect' : 'collected'
              }.svg`)
          " @click="handleMusicCollect(item)" />
          <img :src="require('@/assets/svg/share-gray.svg')" @click="handleShare('', item.object_id, 2, item.name)" />
          <img :src="require('@/assets/svg/download-gray.svg')" @click="handleDownload(item, 'single')" />
          <!-- 购物车 -->
          <img :src="require('@/assets/svg/share-gray.svg')" v-if="!button" />
        </div>
        <!-- 按钮 -->
        <div class="column btn" v-if="button">
          <div class="btn-content">
            <div class="btn-car" @click.stop="
              handleAddGoodsToCart({
                object_id: item.object_id,
                type: 2,
                name: item.name
              })
            ">
              加入购物车
            </div>
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <div class="vf-music-pager" v-if="pager">
        <Pagination v-if="total > 0" :limit.sync="pageSize" :page.sync="currentPage" @pagination="pagination"
          :total="total" />
      </div>
      <!-- <div class="more_cont">
        <More
          v-show="(showMore && isShowMore) || !total"
          :type = 2
          @viewMore="viewMore"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import Tag from './Tag'
import Pagination from './Pagination'
// import More from './More'
import Buttons from '@/layout/mixins/Buttons'
import { orderList } from '@/config'
import { getToken } from '@/utils/author'

export default {
  name: 'MusicList',
  props: {
    // 音乐列表数据
    data: {
      type: Array,
      default: () => []
    },
    // 是否展示表头
    header: {
      type: Boolean,
      default: true
    },
    // 是否展示按钮
    button: {
      type: Boolean,
      default: true
    },
    // data 条数
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 30
    },
    keywordName: {
      default: ''
    },
    // 是否配置分页器
    pager: {
      type: Boolean,
      default: false
    },
    searchByTag: {
      type: Array,
      default: () => []
    },
    showOrder: {
      type: Boolean,
      default: true
    }
  },
  // More
  components: { Tag, Pagination },
  data() {
    return {
      count: 1,
      ind: -1,
      showMore: false,
      orderList,
      orderNo: '',
      isShowMore: false
    }
  },
  computed: {
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    },
    playing() {
      return this.$store.state.music.isPlaying
    }
  },
  watch: {
    '$store.state.music.tmpMusicInfo'(val) {
      const { is_collected, object_id } = val || {}
      if (val) {
        const { data } = this
        const list = [...data]
        if (object_id) {
          const index = data.findIndex((i) => i.object_id === object_id)
          if (index > -1) {
            list[index].is_collect = is_collected
            this.$emit('update:data', list)
          }
        }
      }
    },
    searchByTag: function (newValue) {
      if (newValue.length > 0) {
        this.showMore = true
      } else {
        this.showMore = false
      }
    },
    total: function (newValue) {
      // console.log(newValue, '总数',Math.ceil(newValue/this.limit),'页码')
      if (Math.ceil(newValue / this.limit) == 1) {
        this.isShowMore = true
      } else {
        this.isShowMore = false
      }
    },
    // 页码是否为最后一页
    count: function (newValue) {
      this.isShowMore =
        newValue == Math.ceil(this.total / this.limit) ? true : false
    }
  },
  mixins: [Buttons],
  methods: {
    handleMouseover(index) {
      this.ind = index
    },
    handleMouseleave() {
      this.ind = -1
    },
    changeMusicOrder() {
      this.$emit('onChange', this.orderNo)
    },
    /**
     * 标签关键字高亮 判断是多组关键词还是一组关键词
     */
    brightenKeyword(val, keyword) {
      if (typeof keyword == 'string') {
        var Reg = new RegExp(keyword, 'g')
        val = val + ''
        if (val) {
          return val.replace(Reg, `<span style="color:red">${keyword}</span>`)
        }
      } else {
        keyword.map((keyitem) => {
          var Regs = new RegExp(keyitem, 'g')
          val = val + ''
          if (val) {
            val = val.replace(Regs, `<span style="color:red">${keyitem}</span>`)
          }
        })
        return val
      }
    },
    /**
     * 查看更多
     */
    viewMore() {
      const tagId = this.searchByTag.map((item) => {
        return item.label_id
      })
      window.sessionStorage.setItem('tagId', tagId)
      this.$router.push({
        path: '/search-result',
        query: {
          more: 1,
          keyword_name: this.keywordName
        }
      })
    },
    pagination(info) {
      this.count = info.page
      this.$emit('pagination', info)
    },
    /** 处理音乐收藏问题
     * 2021-12-30 11:57:59
     * @author SnowRock
     * @param item
     * @returns {Promise<void>}
     */
    async handleMusicCollect(item) {
      if (this.musicInfo && this.musicInfo.object_id) {
        if (item.object_id === this.musicInfo.object_id) {
          this.$message.warning('正在播放的音乐请在播放器中操作')
          return
        }
      }
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(
        item.object_id,
        2,
        {
          ...item,
          type: 2,
          labels: item.label
        },
        false
      )
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      span {
        padding-left: 15px !important;
      }
    }
  }
}

.vf-music {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &-table {
    display: table;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 10px;
    background: $base-color;
    box-shadow: 0px 0px 12px 0px rgba(33, 48, 83, 0.07);
    border-radius: 12px;
    // padding: 20px 27px 48px 13px;
    padding: 20px 47px 48px 33px;
    position: relative;

    .order_cont {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      right: 50px;
      top: 10px;

      span {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }

      /deep/.el-select {
        width: 108px !important;
        height: 32px !important;
        border: 1px solid #999999;
        border-radius: 5px;

        .el-input--suffix .el-input__inner {
          width: 108px !important;
          height: 32px !important;
          border: none;
          background: none;
        }

        .el-input__icon {
          line-height: 32px !important;
        }
      }
    }

    .row {
      display: table-row;
    }

    .row.header {
      margin: 0 20px;
    }

    // .row:not(.header):hover {
    //   background: #f5f6fa;
    //   cursor: pointer;
    // }
    // .row:not(.header):hover .before-box,
    // .row:not(.header):hover .after-box {
    //   display: block;
    // }
    .column {
      display: table-cell;
    }

    .header {
      @include font-bold($size: $font-size-md,
        $color: rgba($font-minor-color, 0.8));
      height: 46px;
      padding-bottom: 19px;

      .column {
        border-bottom: 2px solid rgba(195, 195, 205, 0.36);
      }
    }

    .music-item {
      height: 104px;
      padding: 12px 0;
      position: relative;

      &:hover {
        background: #f5f6fa;
        cursor: pointer;

        .before-box,
        .after-box {
          display: block;
        }
      }

      .column {
        vertical-align: middle;
      }

      // .before-box {
      //   @include size(20px, 104px);
      //   border-radius: 12px 0 0 12px;
      //   position: absolute;
      //   top: 0;
      //   left: -20px;
      //   background: #f5f6fa;
      //   display: block;
      // }
      // .after-box {
      //   @include size(20px, 104px);
      //   border-radius: 0 12px 12px 0;
      //   position: absolute;
      //   top: 0;
      //   right: -20px;
      //   background: #f5f6fa;
      //   display: block;
      // }
      .content {
        height: 100%;
        @include center-middle-flex($justify: flex-start);

        .cover {
          @include custom-position($position: relative);
          @include size(107px, 80px);

          .mask_image {
            z-index: 1000;
            width: 80px;
            height: 80px;
            position: absolute;
            top: 0px;
            left: 0px;
            background: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            // display: none;
            opacity: 0;

            img {
              width: 25px;
            }

            &.active-mask {
              opacity: 1;
            }
          }

          // margin-left: 20px;
          &-main {
            @include square(80px);
            @include custom-position($position: absolute, $zindex: 2);
            box-shadow: 0px 0px 16px 0px rgba(33, 48, 83, 0.36);
            border-radius: 2px;
            object-fit: cover;
          }

          &-fixed {
            @include square(71px);
            @include custom-position($position: absolute,
              $right: 0,
              $top: 50%,
              $zindex: 1);
            transform: translateY(-50%);
          }
        }

        .detail {
          margin-left: 16px;
          width: 194px;

          &-name {
            @include font-bold($size: $font-size-md);
            line-height: 25px;
            display: flex;
            align-items: center;

            label {
              display: inline-block;
              cursor: pointer;
              @include text-overflow;
            }

            img {
              margin-left: 6px;
              width: 26px;
              min-width: 26px;
              transform: translateY(-4px);
            }
          }

          &-info {
            @include font-normal($color: rgba($font-minor-color, 0.48));
            line-height: 20px;
            margin-top: 4px;
          }

          &-hot {
            display: inline-block;
            width: 60px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            border-radius: 2px;
            border: 1px solid #E52A0D;
            margin-left: 10px;
            @include font-normal($size: $font-size-xs,
              $color: rgba($theme-color, 0.8));
          }
        }
      }

      .tag {
        .vf-tag-text {
          @include font-normal($size: 16px,
            $color: rgba($font-minor-color, 0.86));
          margin-right: 6px;
        }
      }

      .duration {
        @include font-normal($size: $font-size-sl,
          $color: rgba($font-minor-color, 0.8));
      }

      .icon {
        text-align: right;

        img {
          @include square(25px);
          margin-right: 21px;
        }

        img:nth-last-of-type(1) {
          margin-right: 0;
        }
      }

      .icon:nth-last-of-type(1) {
        margin-right: 0;
      }

      .btn {
        @include font-normal($color: $theme-color);
        width: 120px;

        &-content {
          @include center-middle-flex($justify: flex-end);
        }

        &-car {
          border: 1px solid $theme-color;
          border-radius: 2px;
          padding: 6px 10px;
        }
      }
    }
  }

  &-pager {
    background: $base-color;
    margin-top: 30px;
    position: absolute;
    right: 47px;
  }

  .more_cont {
    position: absolute;
    left: 30px;
    bottom: 120px;
  }
}
</style>
